.modalContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
  backdrop-filter: blur(5px);
}

.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1300px;
}

.logo {
  width: 400px;
}

.modalHeading {
  font-size: 28px;
  color: var(--blue-500);
}

.modalSubheading {
  font-size: 20px;
}

.modalContent {
  font-size: 16px;
}

.subLabel {
  margin-left: 28px;
}

.alert {
  background-color: #fdeded;
  font-size: 18px;
}

.button {
  padding: 14px 36px;
  align-items: center;
  border-radius: 4px;
  background: var(--blue-vivid-300);
  color: var(--white);
  font-size: 16px;
}

.button:hover {
  color: var(--white);
  background-color: var(--blue-vivid-400);
}

.button.outline {
  background: var(--white);
  color: var(--blue-vivid-300);
  border: 1px solid var(--blue-vivid-300);
}

.button.outline:hover {
  background: var(--blue-vivid-300);
  color: var(--white);
}

.modalFooter {
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%221055%22%20height%3D%22137%22%20viewBox%3D%220%200%201055%20137%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20opacity%3D%220.5%22%20d%3D%22M504.5%20141C292.833%20268.333%20-156.5%20411.8%20-160.5%20411L-119.5%20727H1173.5L1215.5%2084L504.5%20141Z%22%20fill%3D%22url%28%23paint0_linear_23374_2310%29%22%2F%3E%0A%3Cpath%20opacity%3D%220.5%22%20d%3D%22M435.5%2027C223.833%20154.333%20-156.5%20411.8%20-160.5%20411L-119.5%20727H1173.5L1210.5%200L435.5%2027Z%22%20fill%3D%22url%28%23paint1_linear_23374_2310%29%22%2F%3E%0A%3Cdefs%3E%0A%3ClinearGradient%20id%3D%22paint0_linear_23374_2310%22%20x1%3D%221006.5%22%20y1%3D%22263%22%20x2%3D%22-12.5002%22%20y2%3D%22629%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%0A%3Cstop%20stop-color%3D%22%230C57AD%22%2F%3E%0A%3Cstop%20offset%3D%221%22%20stop-color%3D%22%230C57AD%22%20stop-opacity%3D%220%22%2F%3E%0A%3C%2FlinearGradient%3E%0A%3ClinearGradient%20id%3D%22paint1_linear_23374_2310%22%20x1%3D%221006.5%22%20y1%3D%22263%22%20x2%3D%22-12.5002%22%20y2%3D%22629%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%0A%3Cstop%20stop-color%3D%22%230C57AD%22%2F%3E%0A%3Cstop%20offset%3D%221%22%20stop-color%3D%22%230C57AD%22%20stop-opacity%3D%220%22%2F%3E%0A%3C%2FlinearGradient%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E');
  background-color: var(--blue-400);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  padding: 2rem;
}

@media only screen and (max-width: 1599px) {
  .modalSvg {
    width: 350px;
  }

  .modal {
    left: 50%;
    top: 50%;
    max-width: 1100px;
  }

  .alert {
    font-size: 16px;
  }

  .logo {
    width: 300px;
  }

  .modalHeading {
    font-size: 20px;
  }

  .modalSubheading {
    font-size: 16px;
  }

  .modalContent {
    font-size: 14px;
    line-height: 1.2;
  }

  .button {
    padding: 10px 24px;
    font-size: 14px;
  }

  .modalFooter {
    padding: 1rem;
  }
}
@media only screen and (max-width: 1299px) {
  .modal {
    max-width: 900px;
    top: 50%;
    max-height: 90vh;
  }
  .modalSvg {
    width: 300px;
  }
}
