.spinnerDropzone {
  min-height: 0vh;
  margin-left: 0px;
  margin-top: 0px;
  padding: 0px;
}

.overlayDivAvatar {
  position: absolute;
  margin-top: -95px;
  margin-left: 55px;
}

.overlayDiv {
  position: absolute;
  margin-top: 19px;
  margin-left: 25px;
}

.overlayRemoveDivIcon {
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  padding-top: 0px;
  margin-top: 26px;
  margin-left: -3px;
}
.overlayRemoveDiv {
  padding-right: 10px;
}

.overlayRemoveDivAvatar {
  position: absolute;
  margin-left: 64px;
  margin-top: -58px;
}

.overlayNameDiv {
  /* position: absolute; */
  font-size: 11px;
  width: 290px;
}

.overlayFailedAlertMessage {
  position: absolute;
  font-size: 13px;
  width: 288px;
  font-weight: 500;
  padding: 0px 20px;
  color: red;
  background-color: white;
  margin-top: 40px;
}

.spinnerDiv {
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  padding-top: 4px;
}

.fileImage {
  width: 60px;
}

.removeFile {
  background-color: red;
  border-radius: 360px;
  width: 36px;
  height: 36px;
}

.avatar {
  width: 160px;
  height: 160px;
  object-fit: cover;
  border-radius: 999px;
}
